export const GET_ORCHARD_REGISTER_LIST_REQUEST = "GET_ORCHARD_REGISTER_LIST_REQUEST";
export const GET_ORCHARD_REGISTER_LIST_SUCCEED = "GET_ORCHARD_REGISTER_LIST_SUCCEED";
export const GET_ORCHARD_REGISTER_LIST_FAILED = "GET_ORCHARD_REGISTER_LIST_FAILED";

export const GET_PLANT_ORCHARD_REGISTER_LIST_REQUEST = "GET_PLANT_ORCHARD_REGISTER_LIST_REQUEST";
export const GET_PLANT_ORCHARD_REGISTER_LIST_SUCCEED = "GET_PLANT_ORCHARD_REGISTER_LIST_SUCCEED";
export const GET_PLANT_ORCHARD_REGISTER_LIST_FAILED = "GET_PLANT_ORCHARD_REGISTER_LIST_FAILED";

export const GET_ORCHARD_REGISTER_DATA_REQUEST = "GET_ORCHARD_REGISTER_DATA_REQUEST";
export const GET_ORCHARD_REGISTER_DATA_SUCCEED = "GET_ORCHARD_REGISTER_DATA_SUCCEED";
export const GET_ORCHARD_REGISTER_DATA_FAILED = "GET_ORCHARD_REGISTER_DATA_FAILED";

export const CREATE_ORCHARD_REGISTER_REQUEST = "CREATE_ORCHARD_REGISTER_REQUEST";
export const CREATE_ORCHARD_REGISTER_SUCCEED = "CREATE_ORCHARD_REGISTER_SUCCEED";
export const CREATE_ORCHARD_REGISTER_FAILED = "CREATE_ORCHARD_REGISTER_FAILED";

export const UPDATE_ORCHARD_REGISTER_REQUEST = "UPDATE_ORCHARD_REGISTER_REQUEST";
export const UPDATE_ORCHARD_REGISTER_SUCCEED = "UPDATE_ORCHARD_REGISTER_SUCCEED";
export const UPDATE_ORCHARD_REGISTER_FAILED = "UPDATE_ORCHARD_REGISTER_FAILED";

export const DELETE_ORCHARD_REGISTER_REQUEST = "DELETE_ORCHARD_REGISTER_REQUEST";
export const DELETE_ORCHARD_REGISTER_SUCCEED = "DELETE_ORCHARD_REGISTER_SUCCEED";
export const DELETE_ORCHARD_REGISTER_FAILED = "DELETE_ORCHARD_REGISTER_FAILED";