export const GET_SYSTEM_LIST_REQUEST = "GET_SYSTEM_LIST_REQUEST";
export const GET_SYSTEM_LIST_SUCCEED = "GET_SYSTEM_LIST_SUCCEED";
export const GET_SYSTEM_LIST_FAILED = "GET_SYSTEM_LIST_FAILED";

export const GET_SYSTEM_DATA_REQUEST = "GET_SYSTEM_DATA_REQUEST";
export const GET_SYSTEM_DATA_SUCCEED = "GET_SYSTEM_DATA_SUCCEED";
export const GET_SYSTEM_DATA_FAILED = "GET_SYSTEM_DATA_FAILED";

export const GET_AVAILABLE_SYSTEMS_REQUEST = "GET_AVAILABLE_SYSTEMS_REQUEST";
export const GET_AVAILABLE_SYSTEMS_SUCCEED = "GET_AVAILABLE_SYSTEMS_SUCCEED";
export const GET_AVAILABLE_SYSTEMS_FAILED = "GET_AVAILABLE_SYSTEMS_FAILED";

export const CREATE_SYSTEM_REQUEST = "CREATE_SYSTEM_REQUEST";
export const CREATE_SYSTEM_SUCCEED = "CREATE_SYSTEM_SUCCEED";
export const CREATE_SYSTEM_FAILED = "CREATE_SYSTEM_FAILED";

export const UPDATE_SYSTEM_REQUEST = "UPDATE_SYSTEM_REQUEST";
export const UPDATE_SYSTEM_SUCCEED = "UPDATE_SYSTEM_SUCCEED";
export const UPDATE_SYSTEM_FAILED = "UPDATE_SYSTEM_FAILED";

export const DELETE_SYSTEM_REQUEST = "DELETE_SYSTEM_REQUEST";
export const DELETE_SYSTEM_SUCCEED = "DELETE_SYSTEM_SUCCEED";
export const DELETE_SYSTEM_FAILED = "DELETE_SYSTEM_FAILED";