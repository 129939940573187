export const GET_RULE_CONFIG_LIST_REQUEST = "GET_RULE_CONFIG_LIST_REQUEST";
export const GET_RULE_CONFIG_LIST_SUCCEED = "GET_RULE_CONFIG_LIST_SUCCEED";
export const GET_RULE_CONFIG_LIST_FAILED = "GET_RULE_CONFIG_LIST_FAILED";

export const GET_RULE_CONFIG_DATA_REQUEST = "GET_RULE_CONFIG_DATA_REQUEST";
export const GET_RULE_CONFIG_DATA_SUCCEED = "GET_RULE_CONFIG_DATA_SUCCEED";
export const GET_RULE_CONFIG_DATA_FAILED = "GET_RULE_CONFIG_DATA_FAILED";

export const CREATE_RULE_CONFIG_REQUEST = "CREATE_RULE_CONFIG_REQUEST";
export const CREATE_RULE_CONFIG_SUCCEED = "CREATE_RULE_CONFIG_SUCCEED";
export const CREATE_RULE_CONFIG_FAILED = "CREATE_RULE_CONFIG_FAILED";

export const UPDATE_RULE_CONFIG_REQUEST = "UPDATE_RULE_CONFIG_REQUEST";
export const UPDATE_RULE_CONFIG_SUCCEED = "UPDATE_RULE_CONFIG_SUCCEED";
export const UPDATE_RULE_CONFIG_FAILED = "UPDATE_RULE_CONFIG_FAILED";

export const DELETE_RULE_CONFIG_REQUEST = "DELETE_RULE_CONFIG_REQUEST";
export const DELETE_RULE_CONFIG_SUCCEED = "DELETE_RULE_CONFIG_SUCCEED";
export const DELETE_RULE_CONFIG_FAILED = "DELETE_RULE_CONFIG_FAILED";

export const GET_AVAILABLE_RULES_REQUEST = "GET_AVAILABLE_RULES_REQUEST";
export const GET_AVAILABLE_RULES_SUCCEED = "GET_AVAILABLE_RULES_SUCCEED";
export const GET_AVAILABLE_RULES_FAILED = "GET_AVAILABLE_RULES_FAILED";