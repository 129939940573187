export const GET_DRIVER_CONFIG_LIST_REQUEST = "GET_DRIVER_CONFIG_LIST_REQUEST";
export const GET_DRIVER_CONFIG_LIST_SUCCEED = "GET_DRIVER_CONFIG_LIST_SUCCEED";
export const GET_DRIVER_CONFIG_LIST_FAILED = "GET_DRIVER_CONFIG_LIST_FAILED";

export const GET_DRIVER_CONFIG_DATA_REQUEST = "GET_DRIVER_CONFIG_DATA_REQUEST";
export const GET_DRIVER_CONFIG_DATA_SUCCEED = "GET_DRIVER_CONFIG_DATA_SUCCEED";
export const GET_DRIVER_CONFIG_DATA_FAILED = "GET_DRIVER_CONFIG_DATA_FAILED";

export const CREATE_DRIVER_CONFIG_REQUEST = "CREATE_DRIVER_CONFIG_REQUEST";
export const CREATE_DRIVER_CONFIG_SUCCEED = "CREATE_DRIVER_CONFIG_SUCCEED";
export const CREATE_DRIVER_CONFIG_FAILED = "CREATE_DRIVER_CONFIG_FAILED";

export const UPDATE_DRIVER_CONFIG_REQUEST = "UPDATE_DRIVER_CONFIG_REQUEST";
export const UPDATE_DRIVER_CONFIG_SUCCEED = "UPDATE_DRIVER_CONFIG_SUCCEED";
export const UPDATE_DRIVER_CONFIG_FAILED = "UPDATE_DRIVER_CONFIG_FAILED";

export const DELETE_DRIVER_CONFIG_REQUEST = "DELETE_DRIVER_CONFIG_REQUEST";
export const DELETE_DRIVER_CONFIG_SUCCEED = "DELETE_DRIVER_CONFIG_SUCCEED";
export const DELETE_DRIVER_CONFIG_FAILED = "DELETE_DRIVER_CONFIG_FAILED";

export const GET_AVAILABLE_DRIVERS_REQUEST = "GET_AVAILABLE_DRIVERS_REQUEST";
export const GET_AVAILABLE_DRIVERS_SUCCEED = "GET_AVAILABLE_DRIVERS_SUCCEED";
export const GET_AVAILABLE_DRIVERS_FAILED = "GET_AVAILABLE_DRIVERS_FAILED";

export const GET_AVAILABLE_DRIVER_CONFIG_REQUEST = "GET_AVAILABLE_DRIVER_CONFIG_REQUEST";
export const GET_AVAILABLE_DRIVER_CONFIG_SUCCEED = "GET_AVAILABLE_DRIVER_CONFIG_SUCCEED";
export const GET_AVAILABLE_DRIVER_CONFIG_FAILED = "GET_AVAILABLE_DRIVER_CONFIG_FAILED";