export const GET_SYSTEM_AREA_LIST_REQUEST = "GET_SYSTEM_AREA_LIST_REQUEST";
export const GET_SYSTEM_AREA_LIST_SUCCEED = "GET_SYSTEM_AREA_LIST_SUCCEED";
export const GET_SYSTEM_AREA_LIST_FAILED = "GET_SYSTEM_AREA_LIST_FAILED";

export const GET_SYSTEM_AREA_DATA_REQUEST = "GET_SYSTEM_AREA_DATA_REQUEST";
export const GET_SYSTEM_AREA_DATA_SUCCEED = "GET_SYSTEM_AREA_DATA_SUCCEED";
export const GET_SYSTEM_AREA_DATA_FAILED = "GET_SYSTEM_AREA_DATA_FAILED";

export const CREATE_SYSTEM_AREA_REQUEST = "CREATE_SYSTEM_AREA_REQUEST";
export const CREATE_SYSTEM_AREA_SUCCEED = "CREATE_SYSTEM_AREA_SUCCEED";
export const CREATE_SYSTEM_AREA_FAILED = "CREATE_SYSTEM_AREA_FAILED";

export const UPDATE_SYSTEM_AREA_REQUEST = "UPDATE_SYSTEM_AREA_REQUEST";
export const UPDATE_SYSTEM_AREA_SUCCEED = "UPDATE_SYSTEM_AREA_SUCCEED";
export const UPDATE_SYSTEM_AREA_FAILED = "UPDATE_SYSTEM_AREA_FAILED";

export const DELETE_SYSTEM_AREA_REQUEST = "DELETE_SYSTEM_AREA_REQUEST";
export const DELETE_SYSTEM_AREA_SUCCEED = "DELETE_SYSTEM_AREA_SUCCEED";
export const DELETE_SYSTEM_AREA_FAILED = "DELETE_SYSTEM_AREA_FAILED";

export const GET_AVAILABLE_SYSTEM_AREAS_REQUEST = "GET_AVAILABLE_SYSTEM_AREAS_REQUEST";
export const GET_AVAILABLE_SYSTEM_AREAS_SUCCEED = "GET_AVAILABLE_SYSTEM_AREAS_SUCCEED";
export const GET_AVAILABLE_SYSTEM_AREAS_FAILED = "GET_AVAILABLE_SYSTEM_AREAS_FAILED";