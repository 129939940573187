export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCEED = "GET_USER_LIST_SUCCEED";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCEED = "GET_USER_DATA_SUCCEED";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";

export const GET_AVAILABLE_CUSTOMERS_REQUEST = "GET_AVAILABLE_CUSTOMERS_REQUEST";
export const GET_AVAILABLE_CUSTOMERS_SUCCEED = "GET_AVAILABLE_CUSTOMERS_SUCCEED";
export const GET_AVAILABLE_CUSTOMERS_FAILED = "GET_AVAILABLE_CUSTOMERS_FAILED";

export const GET_AVAILABLE_SUPERVISORS_REQUEST = "GET_AVAILABLE_SUPERVISORS_REQUEST";
export const GET_AVAILABLE_SUPERVISORS_SUCCEED = "GET_AVAILABLE_SUPERVISORS_SUCCEED";
export const GET_AVAILABLE_SUPERVISORS_FAILED = "GET_AVAILABLE_SUPERVISORS_FAILED";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCEED = "CREATE_USER_SUCCEED";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCEED = "UPDATE_USER_SUCCEED";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCEED = "DELETE_USER_SUCCEED";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";