export const GET_RULE_ENTITY_LIST_REQUEST = "GET_RULE_ENTITY_LIST_REQUEST";
export const GET_RULE_ENTITY_LIST_SUCCEED = "GET_RULE_ENTITY_LIST_SUCCEED";
export const GET_RULE_ENTITY_LIST_FAILED = "GET_RULE_ENTITY_LIST_FAILED";

export const GET_RULE_ENTITY_DATA_REQUEST = "GET_RULE_ENTITY_DATA_REQUEST";
export const GET_RULE_ENTITY_DATA_SUCCEED = "GET_RULE_ENTITY_DATA_SUCCEED";
export const GET_RULE_ENTITY_DATA_FAILED = "GET_RULE_ENTITY_DATA_FAILED";

export const GET_RULE_ENTITY_CHART_REQUEST = "GET_RULE_ENTITY_CHART_REQUEST";
export const GET_RULE_ENTITY_CHART_SUCCEED = "GET_RULE_ENTITY_CHART_SUCCEED";
export const GET_RULE_ENTITY_CHART_FAILED = "GET_RULE_ENTITY_CHART_FAILED";