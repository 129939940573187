// export const getTargetCode=()=>{
//     if (process.env.REACT_APP_TARGET_PROJECT === 'PLT'){
//         return 'PLT'
//     } else if (process.env.REACT_APP_TARGET_PROJECT === 'VLM'){
//             return 'VLM'
//     } else {
//         return 'PLT'
//     }
// }

const getTargetCode=(targetProject, targetEnv)=>{
    if (targetProject === 'PLT'){
        return 'PLT'
    } else if (targetProject === 'VLM' && targetEnv === "LOCAL"){
            return 'VLM_LOCAL'
    } else {
        return 'VLM'
    }
}

const getTextNs=(targetProject)=>{
    return targetProject.toLowerCase();
}



const TARGET_PROJECT = process?.env.REACT_APP_TARGET_PROJECT || "PLT"

const TARGET_ENV = process?.env.REACT_APP_TARGET_ENV || "CLOUD"

const API_ENDPOINT = process?.env.REACT_APP_API_ENDPOINT || "http://localhost:3001"

const TARGET_CODE = getTargetCode(TARGET_PROJECT, TARGET_ENV);

const TEXT_NAMESPACE = getTextNs(TARGET_PROJECT);

const LANGUAGE = process?.env.REACT_APP_LANGUAGE || "en";

const GOOGLE_API_KEY = process?.env.REACT_APP_GOOGLE_API_KEY || "";

export const config = {
    TARGET_PROJECT,
    TARGET_ENV,
    API_ENDPOINT,
    TARGET_CODE,
    TEXT_NAMESPACE,
    LANGUAGE,
    GOOGLE_API_KEY
}