export const GET_NOTIFICATION_LIST_REQUEST = "GET_NOTIFICATION_LIST_REQUEST";
export const GET_NOTIFICATION_LIST_SUCCEED = "GET_NOTIFICATION_LIST_SUCCEED";
export const GET_NOTIFICATION_LIST_FAILED = "GET_NOTIFICATION_LIST_FAILED";

export const GET_UNREAD_NOTIFICATION_COUNT_REQUEST = "GET_UNREAD_NOTIFICATION_COUNT_REQUEST";
export const GET_UNREAD_NOTIFICATION_COUNT_SUCCEED = "GET_UNREAD_NOTIFICATION_COUNT_SUCCEED";
export const GET_UNREAD_NOTIFICATION_COUNT_FAILED = "GET_UNREAD_NOTIFICATION_COUNT_FAILED";

export const GET_NEW_NOTIFICATION_LIST_REQUEST = "GET_NEW_NOTIFICATION_LIST_REQUEST";
export const GET_NEW_NOTIFICATION_LIST_SUCCEED = "GET_NEW_NOTIFICATION_LIST_SUCCEED";
export const GET_NEW_NOTIFICATION_LIST_FAILED = "GET_NEW_NOTIFICATION_LIST_FAILED";

export const NOTIFICATION_MARK_AS_READ_UNREAD_REQUEST = "NOTIFICATION_MARK_AS_READ_UNREAD_REQUEST";
export const NOTIFICATION_MARK_AS_READ_UNREAD_SUCCEED = "NOTIFICATION_MARK_AS_READ_UNREAD_SUCCEED";
export const NOTIFICATION_MARK_AS_READ_UNREAD_FAILED = "NOTIFICATION_MARK_AS_READ_UNREAD_FAILED";

export const NOTIFICATION_MARK_ALL_AS_READ_REQUEST = "NOTIFICATION_MARK_ALL_AS_READ_REQUEST";
export const NOTIFICATION_MARK_ALL_AS_READ_SUCCEED = "NOTIFICATION_MARK_ALL_AS_READ_SUCCEED";
export const NOTIFICATION_MARK_ALL_AS_READ_FAILED = "NOTIFICATION_MARK_ALL_AS_READ_FAILED";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCEED = "DELETE_NOTIFICATION_SUCCEED";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";

export const DELETE_ALL_NOTIFICATION_REQUEST = "DELETE_ALL_NOTIFICATION_REQUEST";
export const DELETE_ALL_NOTIFICATION_SUCCEED = "DELETE_ALL_NOTIFICATION_SUCCEED";
export const DELETE_ALL_NOTIFICATION_FAILED = "DELETE_ALL_NOTIFICATION_FAILED";
