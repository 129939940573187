export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCEED = "USER_LOGIN_SUCCEED";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";
export const USER_LOGOUT_SUCCEED = "USER_LOGOUT_SUCCEED";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_FAILED = "USER_FORGOT_PASSWORD_FAILED";
export const USER_FORGOT_PASSWORD_SUCCEED = "USER_FORGOT_PASSWORD_SUCCEED";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";
export const GET_USER_DETAILS_SUCCEED = "GET_USER_DETAILS_SUCCEED";

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED";
export const UPDATE_USER_DETAILS_SUCCEED = "UPDATE_USER_DETAILS_SUCCEED";
